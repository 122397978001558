import React from "react"
import PageTemplate from '../components/pageTemplate'
import history from '../assets/images/history-banner.png'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="About Raybek Foods" img={data.fileName}>
    <p>Since establishment in early 2007, Raybek Foods has grown from strength to strength to deliver the finest gourmet products on the market.</p>
    <p>Here at Raybek Foods, we aim to meet the ever changing market by continuously striving to provide the freshest products possible by only using the finest quality ingredients.</p>
    <p>With rapid expansion over the last {new Date().getFullYear() - 2007} years, Raybek Foods has expanded its product offerings into fine cheeses, gourmet dips, gourmet sauces olives and exclusive Anti-Pasto ranges catering for the entire market. All of our products are created with the utmost care to create the perfect taste to satisfy your taste buds.</p>
    <p>Now, as a proven market leader with an established reputation for great quality products, Raybek Foods Gourmet Greek Yoghurt is available around Queensland, New South Wales and Victoria for your convenience.</p>
    <img src={history} className="img-fluid mt-4"/>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "maleny-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
